<template>
  <div class="navigation">
    <div class="navigation__list">
      <div class="white__v-btn navigation__list-item">
        <router-link to="/sbor_contacts">Личный кабинет</router-link>
      </div>
      <div class="white__v-btn navigation__list-item">
        <router-link to="/sbor2">Предоставление сведений о перечне учебных изданий, указанный в
          рабочих программах дисциплин (модулей) по истории (истории России, всеобщей истории)
        </router-link>
      </div>
      <div class="white__v-btn navigation__list-item">
        <router-link to="/sbor3">Сбор данных по переводу студентов из других стран
        </router-link>
      </div>
      <div v-if="fullAccess" class="white__v-btn navigation__list-item">
        <router-link :fullAccess="fullAccess" to="/sbor_artificial_intelligence/2022">
          Сбор отчетов по реализации грантов на
          разработку программ бакалавриата и программ магистратуры по профилю «искусственный интеллект», а также на
          повышение квалификации педагогических работников образовательных организаций высшего образования в сфере
          искусственного интеллекта
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "SborList",
  data() {
    return {
      fullAccess: false,
    }
  },
  mounted() {
    this.setFullAccess()
  },
  methods: {
    ...mapGetters('auth', {authData: 'authData'}),

    setFullAccess() {
      const orgsWithAccess = [289, 39, 2733, 411, 168, 464, 2609, 299, 158, 198, 536, 346, 172, 133, 163, 13, 10, 173, 3557]
      if (orgsWithAccess.indexOf(this.authData().orgID) !== -1 ||
          this.authData().realm_access.roles.includes('ADMIN_ROLE')) {
        this.fullAccess = true
      }
    }
  }
}
</script>

<style scoped>

.navigation {
  margin-top: 15px;
}

.navigation__list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation__list-item {
  margin-bottom: 20px;
  font-size: 1.1rem;
  text-align: center;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  transition: all 0.2s;
}

.navigation__list-item:hover {
  background-color: #efefef;
}

.navigation__list-item:active {
  background-color: #ababab;
}

.navigation__list-item > a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 5px 15px;
}

</style>